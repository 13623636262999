<template>
  <ul class="list">
    <li v-for="(item, index) in list" :key="index">
      <h2 class="caseTitle">{{ item.title }}</h2>
      

      <div class="info">
        <div class="subAndClass">
          <p>
            <span class="infoIndex">发布类型：</span
            ><span class="subject">{{ item.type }}</span>
          </p>
          <p>
            <span class="infoIndex">发布对象：</span
            ><span class="subject">
              <h6 style="margin: 4px 0" v-for="(oitem,index) in item.objects" :key="index">{{ oitem }}</h6>
            </span>
          </p>
        </div>
        <div class="reAndDo">
          <p>
            <span class="infoIndex">发布日期：</span
            ><span :class="item.status == '0' ? 'noPost' : ''">{{ item.publish_date }}</span>
          </p>
          <p>
            <span class="infoIndex">创建日期：</span
            ><span>{{ item.createtime }}</span>
          </p>
        </div>
      </div>

      <div class="fnBtn">
        <van-button
          v-if="item.status == '0'"
          type="primary"
          size="mini"
          round
          color="rgb(85, 170, 255)"
          @click.stop="toPost(item, index)"
          >发布</van-button
        >
        <van-button
          v-if="item.status == '0'"
          type="primary"
          size="mini"
          round
          color="rgb(255, 170, 0)"
          @click.stop="toEdit(item.id)"
          >编辑</van-button
        >
        <van-button
          v-if="item.status == '0'"
          type="primary"
          size="mini"
          round
          color="rgb(255, 0, 0)"
          @click.stop="toDel(item, index)"
          >删除</van-button
        >
        <van-button
          v-if="item.status == '1'"
          type="primary"
          size="mini"
          round
          color="rgb(85, 170, 255)"
          @click.stop="toInfo(item)"
          >查看内容</van-button
        >
      </div>
    </li>
  </ul>
  
  <van-overlay :show="showPreText" @click="showPreText = false">
    <div class="descOverlay">
      <div class="block" v-html="preText"></div>
    </div>
  </van-overlay>
</template>

<script setup>
import { defineProps, ref } from "vue";
import { useRouter } from "vue-router";
import { apiGetTeacherInfo, apiTeacherToPost, apiTeacherToDel } from "@/service/notice";
import { Dialog, Toast } from "vant";

const router = useRouter();
const props = defineProps(["list"]);

/**
 * 编辑
 */
const toEdit = async (id) => {
  router.push({
    path: "/teacher/noticeManagement/edit",
    query: { notice_id: id },
  });
}

/**
 * 删除
 */
const toDel = async (item, index) => {
  Dialog.confirm({
    title: "确定删除通知？",
    message: "删除后无法恢复",
  })
  .then(async () => {
    let formData = new FormData()
    formData.append('id', item.id);
    await apiTeacherToDel(formData).then((res) => {
      if(res.code === 1){
        props.list.splice(index, 1)
        Toast.success(res.msg);
      }
    })
  })
}

/**
 * 发布
 */
const toPost = async (item, index) => {
  let formData = new FormData()
  formData.append('id', item.id);
  await apiTeacherToPost(formData).then((res) => {
    if(res.code === 1){
      props.list[index] = res.data
      Toast.success(res.msg);
    }
  })
}

/**
 * 查看详情
 */
let showPreText = ref(false);
let preText = ref("");
const toInfo = async (item) => {
  await apiGetTeacherInfo({
    id: item.id
  }).then((res) => {
    showPreText.value = true;
    preText.value = res.data.contents;
  })
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";
.listIcon {
  display: flex;
  margin-bottom: 10px;
  h2 {
    margin: 0;
    font-size: 10px;
    margin-left: 10px;
  }
}
.list {
  padding: 10px;
  li {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 12px;
    border-radius: 8px;
    .bs();
    h2 {
      margin: 0;
      font-size: 12px;
    }

    .subjectAndTree {
      color: #666;
    }

    .infoIndex {
      color: #777;
    }
    .infoIndex {
      background-color: #fff;
    }
    .subject {
      padding: 0 7px;
      border-radius: 10px;
      background-color: rgb(191, 109, 67);
      color: #fff;
    }
    .noPost {
      padding: 0 7px;
      border-radius: 10px;
      background-color: rgb(255, 0, 0);
      color: #fff;
    }

    .fnBtn {
      display: flex;
      justify-content: end;
      :deep(.van-button) {
        margin-left: 10px;
        padding: 0 15px;
      }
    }
  }
}

:deep(.van-divider) {
  margin: 0;
  margin-bottom: 10px;
}

.info {
  display: flex;

  .subAndClass {
    width: 50%;
    p {
      display: flex;
      justify-content: space-between;
      padding-right: 30px;
    }
    p:nth-child(2) {
      padding-right: 30px;
    }
  }

  .reAndDo {
    flex: 1;
    p {
      display: flex;
      justify-content: space-between;
    }
  }
}

.nothing {
  background-color: #fff;
  border-radius: 8px;
}

.descOverlay {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 100%;
}
.descOverlay .block {
  background-color: #fff;
  line-height: 3vh;
  width: 80vw;
  max-height: 80vh;
  border-radius: 8px;
  padding: 8px;
  overflow-y: auto;
  white-space: pre-wrap;
}

/* 横屏 */
@media screen and (orientation: landscape) {
  .descOverlay .block {
    margin-left: 50px;
  }
}
</style>
