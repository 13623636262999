<template>
  <div class="about">
    <s-header :hasBack="false" :title="'通知管理'" />
    <n-bar :userType="'teacher'" :activeItemName="'noticeManagement'" />
    <support :otherClass="'width40'" />

    <div class="pageContent">
      <div class="modular-base">
        <div class="modular-item">
          <div class="nav">
            <ul class="grade">
              <li><h3>年级</h3></li>
              <li
                class="all"
                :class="grIndex == -1 ? 'geActive' : ''"
                @click="clickGradeAll"
              >
                全部
              </li>
              <li
                v-for="(item, index) in gradeList"
                :key="item.grade_id"
                :class="grIndex == index ? 'geActive' : ''"
                @click="clickGrade(item.grade_id, index)"
              >
                <div>{{ item.gradeName }}</div>
              </li>
            </ul>
            <ul class="class">
              <li><h3>班级</h3></li>
              <li
                class="all"
                :class="clIndex == -1 ? 'clActive' : ''"
                @click="clickClassAll"
              >
                全部
              </li>
              <li
                v-for="(item, index) in classList"
                :key="item.class_id"
                :class="clIndex == index ? 'clActive' : ''"
                @click="clickClass(item.class_id, index)"
              >
                <div>{{ item.className }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <div class="modular-content">
        <div class="modular-item">
          <div class="topIcon">
            <!-- <div class="switchSubject" @click="showSubject = true">
              <van-image
                width="25px"
                height="25px"
                :src="require('@/assets/images/switch.png')"
              />
              <div>{{ subjectName }}</div>
            </div> -->
            <div class="switchSubject"></div>
            <div class="scree" @click="showScree = true">
              <van-image
                width="4vw"
                height="4vw"
                :src="require('@/assets/images/scree.png')"
              />
              <div>筛选</div>
            </div>
            <div class="addNotice" @click="addNotice">
              <van-image
                width="25px"
                height="25px"
                :src="require('@/assets/images/u708.png')"
              />
              <div style="margin-left: 10px">发布通知</div>
            </div>
          </div>

          <div class="tab">
            <van-list
              v-model:loading="noticeLoading"
              :finished="noticeLoadingFinished"
              finished-text="没有更多了"
              @load="getNoticeList"
            >
              <NoticeList :list="noticeList"></NoticeList>
            </van-list>
          </div>
        </div>
      </div>
    </div>

    <!-- 切换学科 -->
    <!-- <van-popup
      v-model:show="showSubject"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-picker
        title="切换学科"
        :columns="subjectList"
        @confirm="onConfirmSubject"
        @cancel="showSubject = false"
      />
    </van-popup> -->

    <!-- 手机竖屏筛选 -->
    <van-popup
      v-model:show="showScree"
      position="left"
      :style="{ height: '100%', width: '80%' }"
    >
      <div class="screeBox">
        <div class="P-grade">
          <h3>年级</h3>
          <ul>
            <li
              v-for="item in gradeList"
              :key="item.grade_id"
              @click="p_grade(item.grade_id)"
              :class="gradeId == item.grade_id ? 'click' : ''"
            >
              {{ item.gradeName }}
            </li>
          </ul>
        </div>

        <div class="P-class">
          <h3>班级</h3>
          <ul>
            <li
              v-for="item in classList"
              :key="item.class_id"
              @click="p_class(item.class_id)"
              :class="classId == item.class_id ? 'click' : ''"
            >
              {{ item.className }}
            </li>
          </ul>
        </div>

        <!-- <div class="P-subject">
          <h3>学科</h3>
          <ul>
            <li
              v-for="item in subjectList"
              :key="item.value"
              @click="p_subject(item.value)"
              :class="subjectId == item.value ? 'click' : ''"
            >
              {{ item.text }}
            </li>
          </ul>
        </div> -->

        <div class="P-bar">
          <div>
            <div class="reset" @click="resetScree">重置</div>
            <div class="confirm" @click="confirmScree">确定</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import NoticeList from "../components/NoticeList.vue";
import {
  apiGetGrade,
  apiGetClassList,
  // apiGetSubject,
  apiGetNewId,
} from "@/service/teacher1.3.js";
import { apiGetTeacherLists } from "@/service/notice";

const router = useRouter();
const route = useRoute();

onBeforeMount(async () => {
  await getGradeList();
  await getClassList();
  // await getSubject();
  await getNoticeList();
});

const addNotice = async () => {
  router.push({
    path: "/teacher/noticeManagement/edit",
  });
};

let stateActive = ref(route.query.mode ? +route.query.mode : 0);

/* let showSubject = ref(false);
let subjectName = ref("切换学科");
let subjectList = ref([]);
let subjectId = ref("");
const getSubject = async (gradeId, classId) => {
  let res = await apiGetSubject({
    grade_id: gradeId ? gradeId : gradeList.value[0].grade_id,
    class_id: classId ? classId : 0,
  });
  if (res) {
    subjectList.value = res.data.map((item) => {
      return {
        text: item.subjectName,
        value: item.subject_id,
      };
    });
  }
}; */

const onConfirmSubject = async (e) => {
  // subjectId.value = e.value;
  // subjectName.value = e.text;
  // showSubject.value = false;
  page = 0;
  noticeList.value = [];
  // notReview.value = [];
  // isReview.value = [];
  await getNoticeList();
};

let gradeList = ref([]);
let gradeId = ref("");
const getGradeList = async () => {
  let res = await apiGetGrade();
  if (res) {
    gradeList.value = res.data;
  }
};

let classList = ref([]);
let classId = ref("");
const getClassList = async (gradeId) => {
  let res = await apiGetClassList({
    grade_id: gradeId ? gradeId : gradeList.value[0].grade_id,
  });
  if (res) {
    classList.value = res.data;
  }
};

let grIndex = ref(-1);
const clickGradeAll = async () => {
  gradeId.value = "";
  grIndex.value = -1;
  await getClassList();
  page = 0;
  noticeList.value = [];
  // notReview.value = [];
  // isReview.value = [];
  await getNoticeList();
};

const clickGrade = async (id, index) => {
  grIndex.value = index;
  gradeId.value = id;
  page = 0;
  noticeList.value = [];
  // notReview.value = [];
  // isReview.value = [];
  await getNoticeList();
  await getClassList(id);
};

let clIndex = ref(-1);
const clickClassAll = async () => {
  clIndex.value = -1;
  classId.value = "";
  page = 0;
  noticeList.value = [];
  await getNoticeList();
};

const clickClass = async (id, index) => {
  clIndex.value = index;
  classId.value = id;
  page = 0;
  noticeList.value = [];
  // notReview.value = [];
  // isReview.value = [];
  await getNoticeList();
};

// 学案数据
let noticeList = ref([]);
// let notReview = ref([]);
// let isReview = ref([]);

// 列表loading效果
let noticeLoading = ref(true);
// 结束loading效果
let noticeLoadingFinished = ref(false);

let page = 0;
let limit = 10;

const getNoticeList = async () => {
  page++;
  let res = await apiGetTeacherLists({
    page: page,
    limit: limit,
    gradeId: gradeId.value,
    classId: classId.value,
  });
  if (res) {
    noticeLoading.value = false;
    
    res.data.forEach((item) => {
      noticeList.value.push(item)
    })

    if (res.data.length < limit) {
      noticeLoadingFinished.value = true;
    }
  } else {
    noticeLoading.value = false;
    noticeLoadingFinished.value = true;
  }
};

let showScree = ref(false);
const p_grade = (id) => {
  gradeId.value = gradeId.value == id ? "" : id;
};
const p_class = async (id) => {
  classId.value = classId.value == id ? "" : id;
  // await getSubject(gradeId.value, classId.value)
};
/* const p_subject = (id) => {
  subjectId.value = subjectId.value == id ? "" : id;
}; */
const confirmScree = async () => {
  showScree.value = false;
  page = 0;
  noticeList.value = [];
  isReview.value = [];
  notReview.value = [];
  await getNoticeList();
};
const resetScree = () => {
  // subjectId.value = "";
  gradeId.value = "";
  classId.value = "";
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";

.modular-content {
  background-color: #fff;
  padding: 6px 10px;
  .topIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .switchSubject {
      display: flex;
      align-items: center;
      font-size: 11px;
    }

    .addNotice {
      display: flex;
      align-items: center;
      font-size: 11px;
    }
  }
}

.nav {
  padding-left: 20px;
  display: flex;
  text-align: center;
  li {
    margin-bottom: 10px;
  }
  h3 {
    margin: 10px 0;
  }
  .grade {
    margin-right: 20px;
  }
  .all {
    box-shadow: 0 0 0 0.5px #18a4e0;
    padding: 10px 15px;
    border-radius: 8px;
  }

  .class,
  .grade {
    li {
      div {
        box-shadow: 0 0 0 0.5px #18a4e0;
        padding: 10px 15px;
        border-radius: 8px;
      }
    }
  }

  .geActive {
    background-color: #18a4e0;
    box-shadow: none;
    color: #fff;
    font-weight: 500;
    border-radius: 8px;
  }
  .clActive {
    background-color: #18a4e0;
    box-shadow: none;
    color: #fff;
    font-weight: 500;
    border-radius: 8px;
  }
}

/* 竖屏 */
@media screen and (orientation: portrait) {
  .modular-base {
    width: 167px;
    height: auto;
    margin: 10px;
    position: fixed;
    top: 30px;
    left: 0;
    bottom: 40px;
    overflow-y: auto;

    .modular-item {
      padding: 4px;
      margin-bottom: 5px;
    }
  }

  .modular-content {
    margin-left: 180px;
    padding-left: 10px;
    height: auto;
    min-height: calc(100vh - 30px - 40px);
    // background-color: @fc;
  }
}

/* 横屏 */
@media screen and (orientation: landscape) {
  .modular-base {
    width: 170px;
    height: auto;
    // margin: 6px 10px 10px 10px;
    position: fixed;
    top: 30px;
    left: 50px;
    bottom: 20px;
    overflow-y: auto;

    .modular-item {
      padding: 4px;
      margin-bottom: 5px;
    }
  }

  .modular-content {
    margin-left: 167px;
    height: auto;
    min-height: calc(100vh - 30px - 20px);
    // background-color: @fc;
  }

  .scree {
    display: none !important;
  }
}

@media screen and (orientation: portrait) and (max-device-width: 770px) {
  .modular-base,
  .switchSubject {
    display: none !important;
  }
  .scree {
    display: flex;
    font-size: 12px;
  }

  .modular-content {
    margin: 0;
  }
}

.screeBox {
  padding: 40px 10px;
  font-size: 12px;
  .P-grade,
  .P-class,
  .P-subject {
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        background-color: #eee;
        padding: 5px 10px;
        border-radius: 3px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
      .click {
        background-color: #18a4e0;
        color: #fff;
      }
    }
  }
  .P-bar {
    position: fixed;
    width: 93%;
    bottom: 0;
    padding-bottom: 80px;
    display: flex;
  }
  .P-bar > div {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #18a4e0;
    border: 1px solid #18a4e0;
    box-sizing: border-box;
    overflow: hidden;
    height: 35px;
    line-height: 35px;
    border-radius: 30px;
    div {
      width: 48%;
      color: #18a4e0;
    }
    div:nth-child(1) {
      background-color: #fff;
      border-radius: 0 0 20px 0;
    }
    div:nth-child(2) {
      color: #fff;
    }
  }
}
</style>
